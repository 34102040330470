import React from "react";

const Menu = () => {
  return (
    <div>
      <div className="h-screen flex justify-center items-center bg-green-300">
        <h1 className="text-9xl uppercase font-black">Menu Page</h1>
      </div>
    </div>
  );
};

export default Menu;
